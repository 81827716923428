import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Aries.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => { 
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Aries Love
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/aries"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Aries</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Aries Love</h4>
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Aries Love</h1>
                <h2 className="text-sm md:text-base ml-4">Mar 21 - Apr 19</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Aries Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Aries Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Aries Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-love");
                  }}>
                  <h5 className="font-semibold text-black">Aries Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-nature");
                  }}>
                  <h5 className="font-semibold text-black">Aries Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-man");
                  }}>
                  <h5 className="font-semibold text-black">Aries Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Aries Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-traits");
                  }}>
                  <h5 className="font-semibold text-black">Aries Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-facts");
                  }}>
                  <h5 className="font-semibold text-black">Aries Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
            <p className="playfair text-black text-xl md:text-3xl">Aries Nature</p><br/>
             Being a Ram, you have the privilege of being a frontiersperson and leader of the zodiac circle. Aries is a dynamic brand that shapes life with fearless, initiation, and efforts with a quirk of courage and boldness. You possess powerful characteristics. Honesty and refreshing frankness is the brand mark of Aries's nature. You face troubles upfront rather than trying to beat around the bush and accuse others of the situation. Deviousness and deceptiveness are thoroughly foreign to you. You are only curious about the concerns that spin around you or impact you. You are a straightforward person and there is nothing that you need to hide from your near and dear ones. Your celibacy and an instant smile on your face soften your egoism and aggressiveness.<br/>
             <br></br>
             You have a strong as a person because you have that sense of purpose and impulse to do something. The word is impossible that defines that things are not possible to do, doesn’t exist in your dictionary. You continuously march forward to accomplish the purpose with great zeal, confidence, positivity, and enthusiasm. You won’t lose hope or get fatigued until you reach your desired goals. You have the possibility of huge growth and pull and push yourself until you reach the desired goals or extend and progress in the way you want. You have secured energy and confidence while implementing the ideas and performing the tasks to accomplish your goals. You want to be the leader of your chosen career or concerned in your profession or business. You are highly courageous and bold and always happy to guide the people from the front. Your co-workers/colleagues and associates are highly pleased to follow in your footsteps as you have an endless drive for perfection.<br/>
             <br></br>
             Do you feel that your stamina is drained? Did you feel that lost from the inside? Not able to get expected outcomes?<br/>
             what are you waiting for??<br/>
             Directly connect to our best and most authentic expert Astrologers. You'll get all your answers and you get rid of your problems.<br/>
             You possess an eagerness to action without a dual thought that might make you hasty. You have that zeal in you that you always want to win any kind of challenging situation that might direct you to unexpected situations sometimes. You participate in any action, or activity with the passion of winning it.<br/>
             <br></br>
             <p className="font-bold text-orange-500 inline-block mr-2">Aries Ruling Planet:</p>Mars <br/>
             Mars, the vibrant red planet is the ruling planet of the sign. It is also guided to as the Roman God of battle, aggression, attack, and conflict. The bold and fearless Ram is always prepared to fight. It is more about the action of doing things and fighting for challenges or adverse situations that come in the way. You despise to sit idle. If you face any obstacles in complex things rather than seating to brood over issues, you are ready to choose to come up with instant alternative plans. You are not those who always cry about their problems first and then think about what they have to do next. <br/>
             <br></br>
             You are the one who just focused to find the best way to tackle all those situations and earnestly start executing it. Mars individuals come directly to the point. You look directly in the eye with honesty and confidence. You would help people whenever you can. You always stay firm, strong, and courageous enough in all situations and seldom cry your heart out. You would choose to be caught over instead of being caught helpless. The ruling planet, Mars always reminds you about the immense energy you possess. The planet also teaches you to use your power and confidence positively.<br/>
             <br></br>
             Is Mars The Reason For Your Hasty Nature?<br/>
             What your zodiac sign says, ask BODHI’s best Astrologers<br/>
             <br></br>
             <p className="font-bold text-orange-500 inline-block mr-2">Ruling House:</p>First (Self)<br/>
             The first house represents the head and physical formation. We can also call it the ascendant in Vedic Astrology. Everything belongs to how the world perceives you or thinks about you. It is not always about your outer personality and who you are. It is rather more about what you bring out of your nature and the results you achieve. It describes the first phase in your life, your childhood. It protects your appearance, self-impression, leadership, fresh kick-starts, and new undertakings. It also protects the start of a relationship & career, wealth business, job, and all the important factors of life.<br/>
             <br></br>
             <p className="playfair text-black text-xl md:text-3xl">Habits</p><br/>
             Aries love to take the central stage and lead the discussion. They will pull and push their powers and put additional energy and effort to be in the front. This quality is perfect to reach quick headway in the short term. But for this, you need to compromise the relationship with your near and loved ones, family, friends, and close associates. Rather than being selfish about your accomplishments, you give priority to your relationships for a more suitable work-life balance. You are more engaged in spending capital instead of saving it. You rarely devote time to organizing your finances. Read more about Aries traits as various roles of relationship.<br/>
             <br></br>
             <p className="font-bold text-orange-500 inline-block mr-2">Element:</p>Fire<br/>
             You belong to the cardinal fire sign and truly possess all the qualities of this element. You are full of passion, energy, zeal, and enthusiasm never get depleted. You are self-disciplined individuals who can channel ample energy constructively and effectively. You are energetic and maintain robust energy which makes you unpredictable. Fire is a brilliant form of power, and energy and spreads light around it. Your sign is a fire feature, and hence you spread light everyplace you go. You also give similar benefits to others without any biases. You have a firm and strong intuition to survive difficult circumstances. This quality permits you to operate in tight situations and obstacles quite effectively. You are honest and accept your mistake.<br/>
             <br></br>
             You are a space lover, creative, and possess high willpower. You follow the idealism of incredible thoughts, amazing skills, and good deeds throughout life.<br/>
             <br></br>
             <p className="playfair text-black text-xl md:text-3xl">Strengths</p><br/>
             You are known to be active, vibrant, enthusiastic, strong, energetic, and dynamic. Your biggest power is your courage. You are courageous enough to accept any kind of challenges that come forth to you and have a fighting spirit for it. You prominently possess optimistic qualities like self-dependence, enthusiasm, energy, generosity, optimism, and bravery. You are straightforward in taking action. You are highly optimistic and confident in tense circumstances as well. You have strong decision-making power and are ambitious to be on top of your respective field. You do the hard work to any extent for fulfilling your desired goals.<br/>
             <br></br>
             <p className="playfair text-black text-xl md:text-3xl">Weaknesses</p><br/>
             You are sometimes rigid and stubborn. You urge on your point of view rather than accepting others’ opinions. You are intolerant and short-tempered, especially when you encounter barriers and criticism in performing your tasks. You just think about your plans and needs and ignore your closed ones in fulfilling them. You are very hasty in actions that may lead you to severe situations at times. The criticism and hindrances in the way of victory make you feel down and depressed at some moment in time. BODHI wishes you all the best in your future efforts in life.
             Everyone has different core strengths and pitfalls. What about yours? Get a detailed study of your strengths and weaknesses with the help of Premium janampatri. <br/> 
              <br></br>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
